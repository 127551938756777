.footer {
  background: $color1;
  color: $color2;
  padding: 2rem 0;
}

.footer__title {
  margin-bottom: 2rem;
}

.footer__social-list {
  display: flex;

  a {
    font-size: 2rem;
    position: relative;
  display: inline-block;
    margin-right: 2rem;

    &::after {
      content: "";
      width: 100%;
      height: 2px;
      display: block;
      background: $color2;
      transform: translateY(-6px) scale(0);
      transition: all 0.1s;
    }

    &:hover {
      &::after {
        transform: translateY(-6px) scale(1);
      }
    }
  }
}
