html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
}

/* Указываем box sizing */

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}


/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
textarea,
select,
button {
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
}

/* Упрощаем работу с изображениями и прочей ерундой*/
embed,
iframe,
img,
object,
video {
  display: block;
  max-width: 100%;
}

[hidden] {
  display: none;
}

.visually-hidden {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
}

body {
  min-width: 320px;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: $font-first;
}

.wrapper {
  max-width: $wrapper-width;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
