$mobile-width: 480px;
$tablet-width: 768px;
$laptop-width: 992px;
$desktop-width: 1200px;
$desktop-full: 1600px;
$desktop-wide: 1920px;
$wrapper-width: 1200px;

$mobile-width-only: 767px;
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

// Шрифты
$font-first: "Roboto", sans-serif;
// $font-roboto: "Roboto", "Arial", sans-serif;

// Цвета
// $header-back: #04859d;
// $header-back: #455D7A;
$color1: #0f4c81;
// $about-back: #ff7c00;
$color2: #facf5a;
$color3: #6394c0;
$color4: #052f54;
$color5: #ff5f00;
$color6: rgb(241, 24, 9);
