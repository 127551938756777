.main {
  background: $color2;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

.wrapper {
  &--fullscreen {
    min-height: 100vh;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  &--info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.main__title {
  font-size: 3rem;
  line-height: 3rem;
}

.info__title {
  margin-bottom: 2rem;

  span {
    display: block;
    font-size: 1.5rem;
  }
}

.info__subtitle {
  font-size: 1.5rem;
  font-weight: bolder;
  line-height: 3rem;
  margin-bottom: 0.75rem;
}

.info__text {
  font-size: 1rem;
  margin-bottom: 0.75rem;
  max-width: 50%;

  a {
    font-weight: bolder;
    transition: all 3s;
    position: relative;
    display: inline-block;

    &::after {
      content: "";
      width: 0;
      height: 1px;
      display: block;
      background: black;
      transition: 300ms;
    }

    &:hover::after {
      width: 100%;
    }
  }
}

.info__list {
  columns: 2;
  max-width: 50%;

  li {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}

.projects {
  background: $color3;
  color: $color2;
}

.projects__title {
  font-size: 4rem;
  margin-bottom: 4rem;
}

.projects__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.proget__list-item {
  width: 350px;
  height: 350px;
  margin-bottom: 2rem;
}

.card {
  display: block;
  width: 100%;
  height: 350px;
  border-radius: 5px;
  box-shadow: 0 5px 15px 2px rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  object-fit: cover;
  overflow: hidden;
  position: relative;

  &:hover {
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.8);
    transform: scale(1.01);
  }
}

.card__img {
  object-fit: cover;
  object-position: left top;
  min-height: 100%;
}

.card__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 1rem;
  font-size: 2rem;
  color: $color2;
  background: rgba($color: $color1, $alpha: 0.9);
  border-radius: 5px;
  transition: all 0.3s;
  opacity: 0;

  .proget__list-item:hover & {
    opacity: 1;

  }
}

.card__description {
  font-size: 20px;
}

.about {
  padding: 5rem 0;

  h2 {
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
}
